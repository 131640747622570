const takeoverContent = `
 <div class="dfy-screen"></div>
 <div class="dfy-takeover">
   <div class="overlay__close">×</div>

   <div class="takeover-content">
      <div class="takeover-title">
         <h1>Give the gift of many more holiday seasons.</h1>
     </div>
   </div>

   <div class="takeover-message">
     <p>This holiday season, please give to find cures for childhood cancers to <br>
     <strong>#GiveKidsALifetime.</strong></p>
   </div>

   <div class="tell-me-more">
     <a href="/save-kids">Donate Today</a>
   </div>

 </div>
 `;

const takeoverStyles = `
 <style>

 .dfy-screen.visible,
 .dfy-takeover.visible {
   display: block;
 }

 .dfy-screen {
   display: none;
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 9000;
 }

 .dfy-takeover {
   display: none;
   position: fixed;
   top: 50%;
   left: 50%;
   background: #fff;
   color: #000;
   text-align: center;
   backface-visibility: hidden;
   transform: translate3d(0, 0, 0);
   transform: translate(-50%, -50%);
   box-shadow: 0 0 50px;
   max-height: 90%;
   overflow: auto;
   z-index: 10000;
   width: 100%;
   max-width: 100%;
 }

 @media (min-width: 760px) {
   .dfy-takeover {
     max-width: 762px;
     width: 95%;
   }
 }

 @media (min-width: 900px) {
   .dfy-takeover {
     max-width: 1338px;
   }
 }

 .takeover-content {
   height: 400px;
   width: 100%;
   background: black url('/fa/min/takeover/eoy/bg.png') center center / cover no-repeat;
 }

 @media (min-width: 760px) {
   .takeover-content {
     height: 475px;
   }
 }

 .takeover-title {
   position: absolute;
   top: 40px;
   left: 0;
   width: 100%;
   color: #fff;
   font-size: 28px;
   font-weight: bold;
   line-height: 1;
   letter-spacing: -1px;
   padding: 0 20px;
 }

 .takeover-title h1 {
   font-size: inherit;
   line-height: inherit;
   font-weight: inherit;
 }

 @media (min-width: 760px) {
   .takeover-title {
     font-size: 60px;
   }
 }

 @media (max-width: 760px) {
   .takeover-title img {
     max-width: 330px;
   }
 }

 .takeover-message {
   color: #353535;
   font-size: 18px;
   font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
   font-weight: bold;
   line-height: 1.2;
   letter-spacing: -0.2px;
   max-width: 800px;
   margin: 0 auto;
   padding: 20px;
   padding-bottom: 0;
 }

 @media (min-width: 760px) {
   .takeover-message {
     font-size: 40px;
     line-height: 1.1;
     letter-spacing: -1px;
   }
 }

 @media (min-width: 900px) {
   .takeover-message {
     font-size: 50px;
     letter-spacing: -1.8px;
     font-weight: 500;
     padding: 0 20px;
     padding-top: 10px;
   }
 }

 .takeover-message p {
   font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
   font-size: 18px;
   font-weight: bold;
   line-height: 1.3;
   letter-spacing: -0.2px;
 }

 @media (min-width: 760px) {
   .takeover-message p {
     font-size: 40px;
     line-height: 1.1;
     letter-spacing: -1px;
   }
 }

 @media (min-width: 900px) {
   .takeover-message p {
     font-size: 50px;
     font-weight: 500;
     line-height: 1.1;
     letter-spacing: -1.8px;
   }
 }

 .dfy-takeover .tell-me-more {
   text-align: center;
   margin-bottom: 34px;
 }

 .dfy-takeover .tell-me-more a {
   display: inline-block;
   padding: 10px 26px;
   background-color: #000000;
   border-radius: 50px;
   color: #fff;
   font-size: 1.2em;
   line-height: 1.375;
   font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
   letter-spacing: normal;
   text-transform: uppercase;
 }

 .dfy-takeover .overlay__close {
   display: block;
   position: absolute;
   border-radius: 0;
   background: none;
   color: #000;
   font-size: 34px;
   font-weight: bold;
   cursor: pointer;
   top: 0;
   right: 0;
   padding: 10px 14px;
   color: #fff;
   z-index: 9000;
 }
 </style>
 `;

// DFY Takeover cookie
const currentCookieName = 'sbfHolidaySeason2020';
$(document).ready(function () {
  const headers = $('.ruby-page-header, .rails-page-header, .main-header');
  const takeoverShown = (document.cookie.indexOf(`${currentCookieName}=1`) !== -1);
  const takeoverEnabled = headers.hasClass('takeover-enabled');

  if (!headers.hasClass('no-takeover') && !takeoverShown && takeoverEnabled) {
    $('head').append(takeoverStyles);
    headers.after(takeoverContent);

    $('.dfy-takeover, .dfy-screen').addClass('visible');

    setTimeout(function () {
      document.cookie = `${currentCookieName}=1; path=/`;
    }, 1500);

    // DFY Takeover Close Button
    $('.dfy-takeover .overlay__close, .dfy-screen').on('click', function () {
      document.cookie = `${currentCookieName}=1; path=/`;
      $('.dfy-takeover, .dfy-screen').removeClass('visible');
    });

    // escape key closes overlay
    $(document).on('keyup.takeoverClose', function (e) {
      if (e.keyCode === 27) {
        document.cookie = `${currentCookieName}=1; path=/`;
        $('.dfy-takeover, .dfy-screen').removeClass('visible');
        $(document).off('keyup.takeoverClose');
      }
    });
  }
});
